.header-small-logo {
  width: 130px;
  height: auto;
}

.header-logo {
  width: 130px;
  height: auto;
}

.header-toggle-btn {
  color: #fff !important;
}

.sidebar-nav-li {
  background: red !important;
}
