.admin-react-select-box .css-13cymwt-control {
  background-color: #111724 !important;
}

.admin-react-select-box .css-13cymwt-control:hover {
  border-color: #fff !important;
}

.admin-react-select-box .css-t3ipsp-control {
  background-color: #111724 !important;
  box-shadow: none !important;
  border-color: #fff !important;
  /* color: #fff !important; */
}

.admin-react-select-box .css-t3ipsp-control:hover {
  border-color: #fff !important;
}

.admin-react-select-box .css-1n6sfyn-MenuList {
  background-color: #111724 !important;
}

.admin-react-select-box .css-1dimb5e-singleValue {
  color: #fff !important;
}
.admin-react-select-box .css-1nmdiq5-menu {
  z-index: 1111 !important;
}
